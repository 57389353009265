import React from 'react'
import Layout from '../../Layout'
import Question from '../Component/Question/index'
import Form from '../Component/Form/index'

import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
function index() {
    let question = [
        {
            question: "1-لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت؟",
            answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از"
        },
        {
            question: "1-لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت؟",
            answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از"
        },
        {
            question: "1-لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت؟",
            answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از"

        },
        {
            question: "1-لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت؟",
            answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از"
        },
        {
            question: "1-لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت؟",
            answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از"
        },
        {
            question: "1-لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت؟",
            answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از"
        }
    ];
    return (
        <Layout noBoxes={true}>
            <Container>
                <section className="commonquestions">
                    <Row>
                        <Col md={12} className="lale">
                            <div className="qus-title">
                                <h1>قوانین</h1>
                            </div>
                            <p>
                          ضمن تشکر از انتخاب خدمات سایت نجات آیدی، لازم به ذکر است این سایت،تایع قوانین جمهوری اسلامی ایران می باشد. 
شرایط و قوانین ذکر شده در ادامه ، مربوط به تمام قسمت های سایت می باشد و کاربر در صورت ثبت سفارش در سایت ،موظف به رعایت آن میباشد.</p>
                          <p>
<br/>
<br/>
<h4>ثبت سفارش:</h4>
به جهت ثبت سفارش ،کاربر موظف است  کلیه اطلاعات درخواستی را با نهایت دقت تکمیل و ارسال نماید. ثبت اطلاعات نادرست موجب لغو درخواست شده و مبلغ پرداختی عودت داده نمی شود.
در هنگام ثبت سفارش ،کاربر میبایست به توضیحات داده شده در صفحه ی مربوط به همان سفارش توجه نماید و پس از تایید نهایی سفارش نسبت به انجام آن اقدام نماید.
<br/>
<br/>
<h4>نگهداری اطلاعات سفارش:</h4>
از زمان اطلاع رسانی به کاربر توسط پیامک ،مبنی بر وضعیت نهایی سفارش، اطلاعات سفارش به مدت یک هفته در قسمت پیگیری وضعیت  قابل مشاهده میباشد. لازم به یادآوری میباشد به جهت حفظ امنیت اطلاعات و سفارشات کاربر، بعد از بازه ی زمانی فوق اطلاعات سفارش از سایت حذف میگردد.
همچنین سایت نجات آیدی هیچ گونه مسئولیتی مبنی بر بایگانی و آرشیو سفارشات ثبت شده بعد از مدت زمان فوق را  ندارد.

اختلال در سرویس اینترنت یا دریافت پیامک کد پیگیری
هرگونه اختلال در سرویس اینترنت یا دریافت کد پیگیری سفارش، باعث از بین رفتن حق کاربر نمیگردد.
<br/>
<br/>
<h4>مدت زمان انجام سفارش:</h4>
مدت زمان انجام سفارش از زمان ثبت نهایی سفارش و پرداخت وجه آن، به مدت یک تا سه روز میباشد. همچنین کلیه بازدید های کاربر از قسمت پیگیری سفارش در سامانه ی مرکزی نجات آیدی ثبت میگردد.
<br/>
<br/>
<h4>
اختیارات سایت:</h4>
سایت نجت آیدی ، در صورت مشاهده ی هر گونه فعالیت غیر مجاز در سایت ، از جمله ثبت سفارش برای به دست آوردن اطلاعات شخصی دیگران یا درج هرگونه محتوای غیر اخلاقی و مغایر با شئونات ملی و اسلامی در سایت نجات آیدی این اختیار را دارا میباشد که سفارش را لغو و برای کاربر محدودیت اعمال نماید.همچنین در صورت لزوم میتواند مراتب را به مراجع قانونی گزارش دهد.
مسئولیت تخلف در این زمینه برعهده ثبت کننده ی سفارش و وارد کننده اطلاعات است.
                          </p>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    )
}

export default index
