import React, { Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";

function Index({ form, data, textEmpty, limit }) {
  const checkEmpty = data => {
    switch (data) {
      case null:
      case undefined:
      case "":
        return false;

      default:
        return true;
    }
  };

  const renderType = (input, tr) => {
    switch (input.type) {
      case "text":
        if (input.img) {
          return (
            <td className="text" className="">
              <img alt="logo" width={45} height={45} src={tr.imgUrl} />
              {tr[input.key]}
            </td>
          );
        }
        return (
          <td className="text" className="">
            {tr[input.key]}
          </td>
        );
      case "enum":
        return <td className="text">{input.enum[tr[input.key]]}</td>;
      case "btn":
        return (
          <td className="text" className="">
            <button>{input.name}</button>
          </td>
        );
      case "date":
        return (
          <td className="text" className="">
            <span className="mx-2">{input.text}</span>
            {tr[input.key]}
          </td>
        );
      default:
        break;
    }
  };

  return (
    <div className="table-admin">
      <Row className="my-3">
        <Col md={4}>
          <div className="search-box">
            <input type="text" placeholder="جستجو کنید ..." />
            <i className="fas fa-search" />
          </div>
        </Col>
        <Col md={4}>
          <div className="sort">
            <span>مرتبط سازی بر اساس</span>
            <select name="" id="">
              <option value="">تاریخ عضویت</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <table className="table-body">
          <tbody>
            {checkEmpty(data) ? (
              data.map((tr, i) => {
                if (i < (limit ? limit : 5)) {
                  return (
                    <Fragment>
                      <tr className="tr">
                        {form.map(i => {
                          return renderType(i, tr);
                        })}
                      </tr>
                    </Fragment>
                  );
                }
              })
            ) : (
              <div className="text-center">
                <td colSpan={15}>{textEmpty}</td>
              </div>
            )}
          </tbody>
        </table>
      </Row>
    </div>
  );
}

export default Index;
