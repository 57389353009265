import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function index() {
    return (
        <footer className="footer">
            <div className="content">
                <Row className="first-row">
                    <Col  sm={2} className="imgbox-response" >
                        <div className="img-box">
                        <img src={window.origin + '/assets/AreaWeb/images/logo.png'} alt="logo"className/>
                        </div>
                    </Col>
                    <Col sm={2} >
                        <div className="links">
                            <Link to="/terms" >قوانین</Link>
                            <Link to="/contact" >تماس با ما</Link>
                        </div>
                    </Col>
                    <Col sm={6} >
                        <div className="description">
                            <h4 className='lale'>مختصری درباره نجات آیدی </h4>
                            <p>مزیت‌های فراوان نجات آیدی شامل سرعت بالا، قابلیت اطمینان، حفظ امنیت اطلاعات شخصی شما و قدرت پشتیبانی و خدمات پس از فروش قابل تحسین است. این سامانه همواره در تلاش است تا مشتریان خود راضی و راحت نگه دارد و با ارائه خدمات با کیفیت و قیمت مناسب، برتری خود را در بازار ایران اثبات کند  </p>
                        </div>
                    </Col>
                                        <Col  sm={2} >
<a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=357959&amp;Code=RjPSlraOo4Gp51Fx5l5b"><img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=357959&amp;Code=RjPSlraOo4Gp51Fx5l5b" alt="" style={{cursor:"pointer"}} id="RjPSlraOo4Gp51Fx5l5b" /></a>
                    </Col>
                </Row>

                <Row>
                    <div className="copy-write">
                    <span>حق کپی محفوظ است . نجات آیدی 1402-1403</span>
                    </div>
                </Row>
            </div>
        </footer>
    )
}

export default index
