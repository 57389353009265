import React,{useContext} from "react";
import { Link } from 'react-router-dom';
import NejatidContext from "../../../../../Context/MainContext";
import MenuIcon from '@mui/icons-material/Menu';

function Index({ drawer, setdrawer }) {
  const context = useContext(NejatidContext)
  return (
    <div className={`drawer ${drawer ? "open-drawer" : "close-drawer"}`}>
      <div className="btn-bar">
        <button
          className="btn-bar"
          onClick={() =>
            setdrawer(preventDefaultDrawer => !preventDefaultDrawer)
          }
        >
          <MenuIcon style={{ fontSize: "48px",color:"white" }} />
        </button>
      </div>
      <div className="links d-flex flex-column">
      <div className="link">
          <span>
            <img
              src={window.origin + "/assets/AreaPanel/images/admin/user.png"}
              alt="logo"
              className
            />
          </span>
          <Link to="/admin/Orders"><span>سفارشات</span></Link>
        </div>
        <div className="link">
          <span>
            <img
              src={window.origin + "/assets/AreaPanel/images/admin/user.png"}
              alt="logo"
              className
            />
          </span>
          <Link to="/admin/Products"><span>محصولات</span></Link>
        </div>


        {/* <div className="link">
          <span>
            <img
              src={window.origin + "/assets/AreaPanel/images/admin/ticket.png"}
              alt="logo"
              className
            />
          </span>
          <Link to="/admin/sendEmail"><span>ارسال ایمیل</span></Link>
        </div> */}
        {/* <div className="link">
          <span>
            <img
              src={
                window.origin + "/assets/AreaPanel/images/admin/dashboard.png"
              }
              alt="logo"
              className
            />
          </span>
          <span>وبلاگ</span>
        </div> */}
        {/* <div className="link">
          <span>
            <img
              src={window.origin + "/assets/AreaPanel/images/admin/cloud.png"}
              alt="logo"
              className
            />
          </span>
          <Link to="/admin/SendSms"><span>اس ام اس</span></Link>
        </div>
        <div className="link">
          <span>
            <img
              src={
                window.origin + "/assets/AreaPanel/images/admin/dashboard.png"
              }
              alt="logo"
              className
            />
          </span>
          <Link to="/admin/Gift"><span>تخفیف</span></Link>
        </div> */}
        <div className="link" onClick={context.handleAdminLogout}>
          <span>
            <img
              src={window.origin + "/assets/AreaPanel/images/admin/exit.png"}
              alt="logo"
              className
            />
          </span>
          <span>خروج </span>
        </div>
      </div>
    </div>
  );
}

export default Index;
