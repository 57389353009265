import React from 'react'
import { Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function index({ data }) {
    return (
        <Col xs={12} sm={4} lg={3}>
            <div className="card-box">
                <div className="image-box">
                    {(data._embedded['wp:featuredmedia'][0].source_url) ? (
                        <img src={data._embedded['wp:featuredmedia'][0].source_url} />
                    ) : (
                        <img
                            src={`https://api.nejatid.ir/${data.pic}`}
                        />
                    )}
                </div>
                <div className="data p-2">
                    
                    <div className="likebox">
                    <CalendarMonthIcon /> تاریخ انتشار : {new Date(data.modified_gmt).toLocaleDateString("fa")}
                    </div>
                <div className="price">
                        <span>{data.title.rendered}</span>
                    </div>
                    <a className="seeclick text-light" href={data.link} target='_blank'>خواندن</a>
                </div>
            </div>
        </Col>
    )
}

export default index
