import React, { useEffect, useContext } from 'react'
import Layout from '../../Layout'
import { Col, Container, Nav, Row, Tab, Form, Button } from "react-bootstrap";
import Maplist from '../../../Common/Components/MapList'
import Swal from 'sweetalert2'
import Card from '../Component/Cards/index'
import Card2 from '../Component/Cards/Index2'
import NejatidContext from './../../../Context/MainContext';
import { Hidden } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";


function Index(props) {
    const context = useContext(NejatidContext)
    const query = new URLSearchParams(props.location.search);
    useEffect(() => {
        if (query.get('order') == "true") {
            Swal.fire(
                {
                    title: 'سفارش با موفقیت ثبت شد',
                    icon: 'success',
                    confirmButtonText: 'متوجه شدم',
                }

            )
        }
        context.handleGetProductsForUser()
        context.handleGetPosts()
    }, [])
    const onChange = (value) => {
        context.setCaptchaValidation(value)
    }
    return (
        <Layout noBoxes={true}>
            <main className="landing">
                <Container fluid>
                    <section className="head">
                    </section>
                </Container>
                <Container>
                    <Row className="d-flex flex-row justify-content-center align-items-center">
                        <Col style={{ animation: "jump-shaking 5s infinite", position: "relative" }} xs={10} sm={11} md={6} lg={4} xl={4} >
                            <img className="lgo" style={{ width: "90%" }} src={window.origin + '/assets/AreaWeb/images/ImageSlider2.png'} alt="logo" />

                        </Col>
                        <Col xs={12} sm={12} md={6} lg={8} xl={8} style={{ textAlign: "center" }}>
                            <Col>
                                <h1 className='animate__animated animate__heartBeat headerText'>نجات آیدی</h1>
                            </Col>
                            <br />
                            <div className="d-flex flex-column" style={{ borderRadius: "10px", alignItems: "center", padding: "10px", color: "#C07F00", position: "relative" }}>
                                <p className="animate__animated animate__shakeY lale" style={{ fontSize: "28px", margin: "0" }}>سامانه ای بی رقیب در آنلاک اپل آیدی

                                </p>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <Container fluid style={{ textAlign: "center" }}>
                    <Hidden only={["sm", "xs"]}>
                        <img style={{ width: "88%" }} src={window.origin + '/assets/AreaWeb/images/howToOrder.png'} alt="logo" />
                    </Hidden>


                </Container>
                <Container id="order">
                    <section className="ourservices">
                        <Row id="order" className="header">
                            <h3 className='lale' style={{ textAlign: "center", textShadow: "rgb(0 0 0) 0px 0px 20px", color: "#4C3D3D" }}>محصولات</h3>
                        </Row>
                        <Maplist Component={Card} data={(context.getProducts !== null) ? (context.getProducts) : ([])} />
                    </section>
                    <div />
                    <div id="peygiri" style={{ boxShadow: "rgb(0 0 0) 3px 4px 5px", width: "100%", backgroundColor: "white", borderRadius: "20px", padding: "10px" }}>
                        <h3 className='lale' style={{ textAlign: "center", color: "#4C3D3D" }}>پیگیری سفارش</h3>
                        {(context.getPayment !== null) ? (<>
                            <Col className="p-2 " xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="img-box">
                                    {(context.getPayment.product !== null) ? (<Col xs={12} sm={12} lg={12}>
                                        <div className="text-center">
                                            <Row>
                                                <Col xs={12} sm={12} md={4} lg={3} xl={3} className='card-box p-2'>

                                                    {(context.getPayment.product.pic === "") ? (
                                                        <img src={window.origin + "/assets/AreaWeb/images/girl.png"} />
                                                    ) : (
                                                        <img style={{ width: "100%",borderRadius:"25px" }}
                                                            src={`${context.ImageAddress}/${context.getPayment.product.pic}`}
                                                        />
                                                    )}
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="mt-2">
                                                            <p className="price"><p style={{ fontWeight: "800",textAlign:"right" }}>نام محصول :</p> {context.getPayment.product.name}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="mt-2">
                                                            <p className="price"><p style={{ fontWeight: "800",textAlign:"right" }}>توضیحات محصول :</p> {context.getPayment.product.description}</p>
                                                        </div>
                                                    </Col>
                                                </Col>
                                                <Col className="p-2" xs={12} sm={12} md={9} lg={9} xl={8}>
                                                    <Row className='d-flex flex-row justify-content-center align-items-center p-3'>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>کد سفارش :</span> <span>{context.getPayment.PaymentNumber}</span></Col>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>تاریخ سفارش : {new Date(context.getPayment.createdDate).toLocaleDateString("fa")}</span></Col>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>نام : </span>{context.getPayment.firstName}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}> نام خانوادگی :</span> {context.getPayment.lastName}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}> شماره موبایل :</span> 0{context.getPayment.userPhone}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>اپل آیدی :</span> {context.getPayment.appleId}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>imei :</span> {context.getPayment.imei}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>مدل گوشی :</span> {context.getPayment.mobileModel}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={12} lg={12} xl={12} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}> ایمیل : </span>{context.getPayment.email}</Col>
                                                    </Row>
                                                    <Row className="mb-2 d-flex flex-row justify-content-around align-items-center">
                                                <Col xs={12} sm={12} md={6} lg={3} xl={3} className={context.getPayment.isPayble ? ("successBadge p-2 mt-2") : ("dangerBadge p-2 mt-2")}>نوع سفارش : {context.getPayment.isPayble ? (<span>قابل پرداخت</span>) : (<span>پرداخت پس از تایید</span>)}</Col>
                                                <Col xs={12} sm={12} md={6} lg={3} xl={3} className={context.getPayment.success ? ("successBadge p-2 mt-2") : ("dangerBadge p-2 mt-2")}>وضعیت پرداخت : {context.getPayment.success ? (<span>پرداخت موفق</span>) : (<span>پرداخت نشده</span>)}</Col>
                                                <Col xs={12} sm={12} md={6} lg={3} xl={3} className={context.getPayment.isCompleted ? ("darkBadge p-2 mt-2") : ((context.getPayment.isCanceled === true) ? ("dangerBadge p-2 mt-2") : ((context.getPayment.isAccepted === true) ? ("successBadge p-2 mt-2") : ("warningBadge p-2 mt-2")))}>وضعیت سفارش : {context.getPayment.isCompleted ? (<span>کامل شده</span>) : ((context.getPayment.isCanceled === true) ? (<span>کنسل شده</span>) : ((context.getPayment.isAccepted === true) ? (<span>تایید شده</span>) : (<span>در حال بررسی</span>)))}</Col>
                                            </Row>
                                                </Col>
                                            </Row>
                                                    <p className="lale">پیام های ادمین</p>
                                                    {(context.getPayment.comment.length > 0) ? (
                                                        context.getPayment.comment.map(item=>
                                                            <Row  className="mt-2 p-2 d-flex flex-row justify-content-center align-items-center" style={{backgroundColor:"#ffd95a",borderRadius:"10px"}}>
                                                            <Col xs={12} sm={12} md={3} lg={3} xl={3}>تاریخ ایجاد : {`${new Date(item.createdDate).getHours().toLocaleString('fa')}:${new Date(item.createdDate).getMinutes().toLocaleString('fa')} | ${new Date(item.createdDate).toLocaleDateString('fa')}`}</Col>
                                                            <Col xs={12} sm={12} md={9} lg={9} xl={9}>پیام : {item.text}</Col>
                                                        </Row>
                                                            )
                                                    ) : (<Row  className="mt-2 p-2 d-flex flex-row justify-content-center align-items-center" style={{backgroundColor:"#ffd95a",borderRadius:"10px"}}>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9}>هنوز پیامی برای سفارش شما ثبت نشده است</Col>
                                                </Row>)}
                                        
                                            <Row className="d-flex flex-row justify-content-start align-items-center" style={{ textAlign: "right" }}>
                                                {(context.getPayment.isPayble) ? (
                                                    <>
                                                            {(context.getPayment.isPayble) ? (
                                                                <p className='text-success p-2' style={{ marginBottom: "0px", fontSize: "12px", color: "#4C3D3D", textAlign: "center" }}>قیمت سفارش : {`${`${context.getPayment.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} تومان`}</p>
                                                            ) : (
                                                                <>
                                                                    <span style={{ marginBottom: "0px", fontSize: "12px", color: "#4C3D3D" }}>قیمت : <span className="text-info p-2">نیاز به بررسی</span></span>
                                                                    <br />
                                                                    <p style={{ marginBottom: "0px", fontSize: "12px", color: "black", padding: "10px" }}>برای سفارشاتی که قیمت برای آنها تعیین نشده است ادمین بعد از بررسی و قابل انجام بودن سفارش شما فاکتور را برای شما صادر خواهد کرد و از طریق همین همین صفحه میتوانید پرداخت را انجام دهید</p></>
                                                            )}
                                                        <br />
                                                        <br />
                                                        <hr />
                                                        {(context.getPayment.success === false) ? (
                                                            <button className='btnby' onClick={() => { context.handlePayFactor() }}>پرداخت سفارش</button>
                                                        ) : (null)}

                                                    </>
                                                ) : (null)}

                                            </Row>

                                        </div>

                                    </Col>) : (null)}

                                </div>

                            </Col>
                        </>) : (<>
                            <Row>
                                <Col>
                                    <Form.Label>کد پیگیری</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        context.setPaymentId(e.target.value)
                                        context.validator.current.showMessageFor("کد پیگیری");
                                    }} type="number" name="کد پیگیری" placeholder="کد پیگیری خود را وارد کنید" />
                                    {context.validator.current.message(
                                        "کد پیگیری",
                                        context.getPaymentId,
                                        "required|min:5"
                                    )}
                                </Col>
                                <Col>
                                    <Form.Label>شماره موبایل</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        context.setUserPhone(e.target.value)
                                        context.validator.current.showMessageFor("شماره موبایل");
                                    }} name="شماره موبایل" type="number" placeholder="شماره موبایل خود را وارد کنید" />
                                    {context.validator.current.message(
                                        "شماره موبایل",
                                        context.getUserPhone,
                                        "required|min:11|max:11"
                                    )}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <br />
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        onChange={onChange}
                                        hl="fa"
                                    />
                                </Col>
                            </Row>
                            <Col>
                                <div className="form-ques-box">
                                    <div className="bt-box">
                                        <button onClick={() => { context.handleUserTrackOrder() }} disabled={(context.getLoading === true) ? (true) : (false)}>{(context.getLoading === true) ? ("در حال بررسی") : ("جستجو")}</button>
                                    </div>
                                </div>
                            </Col>
                        </>)}

                    </div>

                    <section className="ourservices">
                        <div id="blog" style={{ marginBottom: "35px" }} />
                        <Row className="header">
                            <h3 className='lale' style={{ textAlign: "center", textShadow: "rgb(0 0 0) 0px 0px 20px", color: "#4C3D3D" }}>اخبار</h3>
                        </Row>

                        <Maplist Component={Card2} data={(context.getPosts !== null) ? (context.getPosts) : ([])} />
                    </section>
                    <section className="whyus">
                        <Row className="why">
                            <Col xs={12} sm={6} md={5}>
                                <div className="imabox">
                                    <div className="shadow"></div>
                                    <img src={window.origin + "/assets/AreaWeb/images/1212.png"} alt="" />
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={5}>
                                <div className="textbox">
                                    <span className='lale'>چرا نجات آیدی؟</span>
                                    <p> با استفاده از تکنولوژی پیشرفته و تیم متخصصی خود، نجات آیدی توانسته است روش‌های حصری و خاص را برای آنلاک کردن اپل آیدی ها به کار بگیرد. این سامانه با ارائه راهکارهایی قانونی و امن، از هرگونه مشکلی برای کاربران خود جلوگیری می‌کند
                                        و همچنین مزیت‌های فراوان نجات آیدی شامل سرعت بالا، قابلیت اطمینان، حفظ امنیت اطلاعات شخصی شما و قدرت پشتیبانی و خدمات پس از فروش قابل تحسین است. این سامانه همواره در تلاش است تا مشتریان خود راضی و راحت نگه دارد و با ارائه خدمات با کیفیت و قیمت مناسب، برتری خود را در بازار ایران اثبات کند
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Container>
            </main>
        </Layout >
    )
}

export default Index

