import React, { useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import NejatidContext from "../../../../../Context/MainContext";
import ReCAPTCHA from "react-google-recaptcha";

function Index() {
  const context = useContext(NejatidContext);
  const onChange = (value) => {
    context.setCaptchaValidation(value)
  }
  return (
    <div className="container-fluid">
      <Row>
        <div className="login-admin">
          <Col md={4}>
            <div className="form-login">
              <div className="title-form-login">
                <h2>ورود</h2>
              </div>
              <div className="form-login-body">
                <label>نام کاربری</label>
                <input onChange={(e) => {
                  context.setAdminUsername(e.target.value)
                }} type="text" />
                <label>رمز عبور</label>
                <input onChange={(e) => {
                  context.setAdminPassword(e.target.value)
                }} type="password" />
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ReCAPTCHA
                    hl="fa"
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={onChange}
                  />
                </Col>
                <div className="d-flex justify-content-start">
                  <button onClick={context.handleAdminLogin}>ورود</button>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </div>
  );
}

export default Index;
