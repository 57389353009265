import React from 'react'
function index({ data }) {
    return (
        <div className="qus-qus-box">
            {
                data.map((data) => <div className="ques-box">
                    <h5>{data.question}</h5>
                    <p>{data.answer}</p>
                </div>)
            }
        </div>
    )
}

export default index