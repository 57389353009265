
import React, { useState, useContext , useEffect } from 'react';
import { Button, Modal, Row , Col } from 'react-bootstrap';
import NejatidContext from '../../../../Context/MainContext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import axios from 'axios';


const Index = () => {
    const context = useContext(NejatidContext)
    const [show, setShow] = useState(false);
    const [getName, setName] = useState("");
    const [getDesc, setDesc] = useState("");
    const [getProductModel, setProductModel] = useState(true);
    const [getAmount, setAmount] = useState(0);
    const [getPaymentAmount, setPaymentAmount] = useState(0);
    const [getPaymentDesc, setPaymentDesc] = useState("");
    const [getPic, setPic] = useState("");
    const [age, setAge] = React.useState('');
    const [getPaymentMethod, setPaymentMethod] = React.useState(false);

    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    const handleClose = () => {
        context.setOrderModal(false)
        setPaymentDesc("")
    };
    const handleShow = () => context.setOrderModal(true);
    const handleAcceptProduct = async () => {
        try {
            if (context.validator.current.allValid()) {
                let body ={
                    "id":context.getPayment._id,
                    "amount":getPaymentAmount,
                    "isPayble":getPaymentMethod,
                }
                await axios.post(`${context.backendAddress}/admin/payments/accept`, (body), {
                    headers: {
                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        toast.success("سفارش با موفقیت تغییر یافت", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.handleGetPayments()
                        handleClose()
                    }).catch(err => {
                        toast.error("مشکلی در تایید سفارش به وجود آمده است", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.setLoading(false)
                    })
            } else {
                context.validator.current.showMessages();
                context.forceUpdate(1);
                context.setLoading(false)
            }
        } catch (ex) {
            console.log(ex);
            context.setLoading(false)
        }
    };
    const handleSendMessage = async () => {
        try {
            if (context.validator.current.allValid()) {
                let body ={
                    "id":context.getPayment._id,
                    "comment":getPaymentDesc,
                }
                await axios.post(`${context.backendAddress}/admin/paymentP/sendMessage`, (body), {
                    headers: {
                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        toast.success("پیام با موفقیت برای سفارش ثبت شد", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.handleGetPayments()
                        handleClose()
                    }).catch(err => {
                        toast.error("مشکلی در ثبت پیام به وجود آمده است", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.setLoading(false)
                    })
            } else {
                context.validator.current.showMessages();
                context.forceUpdate(1);
                context.setLoading(false)
            }
        } catch (ex) {
            console.log(ex);
            context.setLoading(false)
        }
    };
    const handleCompletePayment = async () => {
        try {
            if (context.validator.current.allValid()) {
                await axios.get(`${context.backendAddress}/admin/payment/completePayment/${context.getPayment._id}`, {
                    headers: {
                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        console.log(res.data)
                        toast.success("سفارش با موفقیت تکمیل شد", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.handleGetPayments()
                        handleClose()
                    }).catch(err => {
                        toast.error("مشکلی در تکمیل سفارش به وجود آمده است", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.setLoading(false)
                    })
            } else {
                context.validator.current.showMessages();
                context.forceUpdate(1);
                context.setLoading(false)
            }
        } catch (ex) {
            console.log(ex);
            context.setLoading(false)
        }
    };
    const handleCancelPayment = async () => {
        try {
            if (context.validator.current.allValid()) {
                await axios.get(`${context.backendAddress}/admin/p/cancel/${context.getPayment._id}`, {
                    headers: {
                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        toast.success("سفارش با موفقیت کنسل شد", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.handleGetPayments()
                        handleClose()
                    }).catch(err => {
                        toast.error("مشکلی در کنسل کردن سفارش به وجود آمده است", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.setLoading(false)
                    })
            } else {
                context.validator.current.showMessages();
                context.forceUpdate(1);
                context.setLoading(false)
            }
        } catch (ex) {
            console.log(ex);
            context.setLoading(false)
        }
    };

useEffect(()=>{
    if(context.getPayment !== null){
        setPaymentAmount(context.getPayment.amount)
    }
    
},[])
    return (
        <>
            
            <Modal
                style={{ direction: "rtl" }}
                show={context.getOrderModal}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>مشاهده ی سفارش</Modal.Title>
                </Modal.Header>
                <Modal.Body>
           
    {/* <Col className="p-2" xs={12} sm={12} md={12} lg={12} xl={12}><img style={{ width: "64px", height: "64px",textAlign:"right"}} src={window.origin + "/assets/AreaPanel/images/admin/admin.png"} /></Col> */}
 {(context.getPayment !== null) ? (   <Col className="p-2 " xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="img-box">
                                    {(context.getPayment.product !== null) ? (<Col xs={12} sm={12} lg={12}>
                                        <div className="text-center">
                                            <Row>
                                                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='card-box p-2'>

                                                    {(context.getPayment.product.pic === "") ? (
                                                        <img src={window.origin + "/assets/AreaWeb/images/girl.png"} />
                                                    ) : (
                                                        <img style={{ width: "100%",borderRadius:"25px" }}
                                                            src={`${context.ImageAddress}/${context.getPayment.product.pic}`}
                                                        />
                                                    )}
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="mt-2">
                                                            <p className="price"><p style={{ fontWeight: "800",textAlign:"right" }}>نام محصول :</p> {context.getPayment.product.name}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="mt-2">
                                                            <p className="price"><p style={{ fontWeight: "800",textAlign:"right" }}>توضیحات محصول :</p> {context.getPayment.product.description}</p>
                                                        </div>
                                                    </Col>
                                                </Col>
                                                <Col className="p-2" xs={12} sm={12} md={9} lg={9} xl={9}>
                                                    <Row className='d-flex flex-row justify-content-center align-items-center p-3'>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>کد سفارش :</span> <span>{context.getPayment.PaymentNumber}</span></Col>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>تاریخ سفارش : {new Date(context.getPayment.createdDate).toLocaleDateString("fa")}</span></Col>
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>نام : </span>{context.getPayment.firstName}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}> نام خانوادگی :</span> {context.getPayment.lastName}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}> شماره موبایل :</span> 0{context.getPayment.userPhone}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>اپل آیدی :</span> {context.getPayment.appleId}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>imei :</span> {context.getPayment.imei}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}>مدل گوشی :</span> {context.getPayment.mobileModel}</Col>
                                                        <Col className="p-2" xs={12} sm={12} md={12} lg={12} xl={12} style={{padding:"10px",textAlign:"right"}}><span style={{ fontWeight: "800" }}> ایمیل : </span>{context.getPayment.email}</Col>
                                                    </Row>
                                                    <Row className="mb-2 d-flex flex-row justify-content-around align-items-center">
                                                <Col xs={12} sm={12} md={6} lg={3} xl={3} className={context.getPayment.isPayble ? ("successBadge p-2 mt-2") : ("dangerBadge p-2 mt-2")}>نوع سفارش : {context.getPayment.isPayble ? (<span>قابل پرداخت</span>) : (<span>پرداخت پس از تایید</span>)}</Col>
                                                <Col xs={12} sm={12} md={6} lg={3} xl={3} className={context.getPayment.success ? ("successBadge p-2 mt-2") : ("dangerBadge p-2 mt-2")}>وضعیت پرداخت : {context.getPayment.success ? (<span>پرداخت موفق</span>) : (<span>پرداخت نشده</span>)}</Col>
                                                <Col xs={12} sm={12} md={6} lg={3} xl={3} className={context.getPayment.isCompleted ? ("darkBadge p-2 mt-2") : ((context.getPayment.isCanceled === true) ? ("dangerBadge p-2 mt-2") : ((context.getPayment.isAccepted === true) ? ("successBadge p-2 mt-2") : ("warningBadge p-2 mt-2")))}>وضعیت سفارش : {context.getPayment.isCompleted ? (<span>کامل شده</span>) : ((context.getPayment.isCanceled === true) ? (<span>کنسل شده</span>) : ((context.getPayment.isAccepted === true) ? (<span>تایید شده</span>) : (<span>در حال بررسی</span>)))}</Col>
                                            </Row>
                                                </Col>
                                            </Row>
                                                    <p className="lale">پیام های ادمین</p>
                                                    {(context.getPayment.comment.length > 0) ? (
                                                        context.getPayment.comment.map(item=>
                                                            <Row  className="mt-2 p-2 d-flex flex-row justify-content-center align-items-center" style={{backgroundColor:"#ffd95a",borderRadius:"10px"}}>
                                                            <Col xs={12} sm={12} md={3} lg={3} xl={3}>تاریخ ایجاد : {`${new Date(item.createdDate).getHours().toLocaleString('fa')}:${new Date(item.createdDate).getMinutes().toLocaleString('fa')} | ${new Date(item.createdDate).toLocaleDateString('fa')}`}</Col>
                                                            <Col xs={12} sm={12} md={9} lg={9} xl={9}>پیام : {item.text}</Col>
                                                        </Row>
                                                            )
                                                    ) : (<Row  className="mt-2 p-2 d-flex flex-row justify-content-center align-items-center" style={{backgroundColor:"#ffd95a",borderRadius:"10px"}}>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9}>هنوز پیامی برای سفارش شما ثبت نشده است</Col>
                                                </Row>)}
                                                    <p className="lale">بازدید های کاربر</p>
                                                    {(context.getPayment.visits.length > 0) ? (
                                                        context.getPayment.visits.map(item=>
                                                            <Row  className="mt-2 p-2 d-flex flex-row justify-content-center align-items-center" style={{backgroundColor:"green",borderRadius:"10px",color:"white"}}>
                                                            <Col xs={12} sm={12} md={3} lg={3} xl={3}>تاریخ بازدید : {`${new Date(item.createdDate).getHours().toLocaleString('fa')}:${new Date(item.createdDate).getMinutes().toLocaleString('fa')} | ${new Date(item.createdDate).toLocaleDateString('fa')}`}</Col>
                                                        </Row>
                                                            )
                                                    ) : (<Row  className="mt-2 p-2 d-flex flex-row justify-content-center align-items-center" style={{backgroundColor:"red",borderRadius:"10px",color:"white"}}>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9}>هنوز کاربر بازدیدی از صفحه ی پیگیری سفارش نداشته است</Col>
                                                </Row>)}
                                        </div>

                                    </Col>) : (null)}

                                </div>

                            </Col>) : (null)}
<Row style={{direction:"rtl"}} className="p-3" xs={12} sm={12} md={12} lg={12} xl={12}>
<Col xs={12} sm={12} md={6} lg={6} xl={6}>
<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">وضعیت سفارش</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={getPaymentMethod}
    label="وضعیت سفارش"
    onChange={(event,value)=>{
        setPaymentMethod(value.props.value)
    }}
  >
    <MenuItem value={true}>قابل پرداخت</MenuItem>
    <MenuItem value={false}>پرداخت پس از تایید</MenuItem>
  </Select>
</FormControl></Col>
<Col xs={12} sm={12} md={6} lg={6} xl={6}>
<TextField type="number" fullWidth id="outlined-basic" onChange={(e)=>{
    setPaymentAmount(e.target.value)
}} label="مبلغ سفارش" variant="outlined" />
</Col>

</Row>
    <div className='form-ques-box input'>
    <p for="mobile" style={{textAlign:"right"}}>توضیحات در مورد سفارش</p>
    <textarea onChange={(e)=>{
        setPaymentDesc(e.target.value)
    }} className="w-100" rows={1} />
    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        بستن
                    </Button>
                    <Button onClick={handleSendMessage} variant="success">ثبت پیام</Button>
                    <Button onClick={handleAcceptProduct} variant="success">تغییر سفارش</Button>
                    <Button onClick={handleCompletePayment} variant="dark">تکمیل سفارش</Button>
                    <Button onClick={handleCancelPayment} variant="danger">کنسل کردن</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;
