import React from 'react'
import { Marker } from 'react-map-gl'
import Pin from './Pin'

const Mark = ({ mark }) => {


    return (
        <Marker
            longitude={mark.longitude}
            latitude={mark.latitude}
        >
            <Pin size={24} />
        </Marker>
    );


}

export default Mark
