import React, { Fragment } from 'react'

function index() {
    return (
        <Fragment>
            <div className="left-box  light"></div>
            <div className="left-box"></div>
        </Fragment>
    )
}

export default index
