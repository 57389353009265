import React,{useContext} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import NejatidContext from '../../Context/MainContext';
import HomeIcon from '@mui/icons-material/Home';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Link } from 'react-router-dom';
import BookIcon from '@mui/icons-material/Book';
import { HashLink } from 'react-router-hash-link';


export default function TemporaryDrawer() {
const context = useContext(NejatidContext)
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      onClick={context.setDrawer(false)}
      onKeyDown={context.setDrawer(false)}
    >
        <p style={{textAlign:"center",margin:"20px"}}><img style={{ width: "64px" }} src={window.origin + '/assets/AreaWeb/images/logo.png'} alt="logo" /></p>
        <h4 style={{textAlign:"center", textShadow: "rgb(0 0 0) 0px 0px 20px", color: "#4C3D3D",fontFamily:'Lalezar !important'}}>نجات آیدی</h4>
      <List>
      <ListItem >
            <ListItemButton onClick={()=>{context.setMenu(false)}} button component={Link} to="/" style={{backgroundColor:"#4C3D3D",borderRadius:"20px",padding:"5px"}}>
              <ListItemIcon>
              <HomeIcon style={{fontSize:"48px",color:"white"}} />
              </ListItemIcon>
              <ListItemText style={{textAlign:"right",fontFamily:"iransans",fontSize:"22px",marginRight:"10px"}} primary="خانه" />
            </ListItemButton>
          </ListItem>
      <ListItem >
            <ListItemButton onClick={()=>{context.setMenu(false)}} button component={HashLink} to="/#order" style={{backgroundColor:"#4C3D3D",borderRadius:"20px",padding:"5px"}}>
              <ListItemIcon>
              <ShoppingBasketIcon style={{fontSize:"48px",color:"white"}} />
              </ListItemIcon>
              <ListItemText style={{textAlign:"right",fontFamily:"iransans",fontSize:"22px",marginRight:"10px"}} primary="سفارش" />
            </ListItemButton>
          </ListItem>
      <ListItem >
            <ListItemButton onClick={()=>{context.setMenu(false)}} button component={HashLink} to="/#peygiri" style={{backgroundColor:"#4C3D3D",borderRadius:"20px",padding:"5px"}}>
              <ListItemIcon>
              <LocationSearchingIcon style={{fontSize:"48px",color:"white"}} />
              </ListItemIcon>
              <ListItemText style={{textAlign:"right",fontFamily:"iransans",fontSize:"22px",marginRight:"10px"}} primary="پیگیری سفارش" />
            </ListItemButton>
          </ListItem>
      <ListItem >
            <ListItemButton onClick={()=>{context.setMenu(false)}} button component={HashLink} to="/#blog" style={{backgroundColor:"#4C3D3D",borderRadius:"20px",padding:"5px"}}>
              <ListItemIcon>
              <BookIcon style={{fontSize:"48px",color:"white"}} />
              </ListItemIcon>
              <ListItemText style={{textAlign:"right",fontFamily:"iransans",fontSize:"22px",marginRight:"10px"}} primary="بلاگ" />
            </ListItemButton>
          </ListItem>
      <ListItem >
            <ListItemButton onClick={()=>{context.setMenu(false)}} button component={Link} to="/contact" style={{backgroundColor:"#4C3D3D",borderRadius:"20px",padding:"5px"}}>
              <ListItemIcon>
              <RingVolumeIcon style={{fontSize:"48px",color:"white"}} />
              </ListItemIcon>
              <ListItemText style={{textAlign:"right",fontFamily:"iransans",fontSize:"22px",marginRight:"10px"}} primary="تماس با ما" />
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
      <Drawer
      dir="rtl"
            anchor={"right"}
            transitionDuration={0}
            open={context.getMenu}
            onClose={()=>{context.setMenu(false)}}
          >
            {list('right')}
          </Drawer>
        </React.Fragment>
        </div>
        
  );
}