import React from 'react'
import Form from '../../../../Common/Components/Panel/Admin/Form'
function Edit() {
    return (
        <div>
            <Form />
        </div>
    )
}

export default Edit
