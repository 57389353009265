import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import JCalendar from 'reactjs-persian-calendar'
import moment from 'jalali-moment'
import jalaliJs from 'jalaali-js'
import NejatidContext from '../../../../Context/MainContext';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
  const context = useContext(NejatidContext);
  const { onClose, selectedValue } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  return (
    <Dialog maxWidth={"lg"} onClose={handleClose} aria-labelledby="simple-dialog-title" open={context.getCalendarDateStart}>
        <JCalendar 
      locale={'fa'} 
      color={'#FFD95A'}
      size={30}
      onClick={(e)=>{
        let m = moment(e, 'YYYY-MM-DD')
        console.log([m.format('YYYY'),m.format('MM'),m.format('DD')])
        let g = jalaliJs.toGregorian(parseInt(m.format('YYYY')) , parseInt(m.format('MM')) , parseInt(m.format('DD')));
        context.setDateStart(`${g.gy}-${g.gm}-${g.gd}`)
        context.setDateStartShow(e)
        onClose(selectedValue)
      }} 
      itemRender={(key, item, children) => children}
  />
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function Index() {
  const context = useContext(NejatidContext);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    context.setCalendarDateStart(true);
  };

  const handleClose = (value) => {
    context.setCalendarDateStart(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <button  className="btnDate" onClick={handleClickOpen}>
          {(context.getDateStartShow === null) ? ("تاریخ شروع را انتخاب کنید") : (context.getDateStartShow)}
      </button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}