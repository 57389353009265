import React from 'react'
import { Col, Row } from "react-bootstrap";
function index({data}) {
    return (
        <Row className="pic-comp">
            {
                data.map((data) =>{
                return(
                <Col xs={4} className="comp">
                    <div className="box">
                        <img src={data.src} alt="" />
                        <div className="dec">
                            <span className="name">{data.name}</span>   <span className="role">{data.role}</span>
                        </div>
                    </div>
                    </Col>
                )
                }
                )
            }
        </Row>
    )
}

export default index
