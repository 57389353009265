import React from 'react'
import { Row } from "react-bootstrap";
function index({ Component, data }) {
    return (
        <Row className="justify-content-center">
            {data.map((data) => <Component data={data} />)}
        </Row>
    )
}

export default index
