import React from 'react'
import { Form, Row, FormControl , InputGroup} from 'react-bootstrap';

function index({ data }) {
    return (
        <div className="form-ques-box">
            {
                data.map((data) => {
                    switch (data.type) {
                        case "text":
                            return (
                                <div className="inp-box">
                                    <label for="fname">{data.label}</label>
                                    <input type="text" id="fname" name="fname" />
                                </div>
                            )
                        case "textarea":
                            return (
                                <div className="tex-box">
                                    <label for="story">{data.label}</label>
                                    <textarea id="story" name="story" />
                                </div>
                            )
                    }

                }
                )
            }
        </div>
    )
}

export default index