
import React, { useState, useContext } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import NejatidContext from '../../../../Context/MainContext';
import { toast } from 'react-toastify';
import axios from 'axios';

const Index = () => {
    const context = useContext(NejatidContext)
    const [show, setShow] = useState(false);
    const [getName, setName] = useState("");
    const [getDesc, setDesc] = useState("");
    const [getProductModel, setProductModel] = useState(true);
    const [getAmount, setAmount] = useState(0);
    const [getPic, setPic] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAddProduct = async () => {
        context.setLoading(true)
        try {
            if (context.validator.current.allValid()) {
                let Form = new FormData()
                Form.append("name", getName)
                Form.append("description", getDesc)
                Form.append("price", getAmount)
                Form.append("productPhoto", getPic)
                Form.append("isPayble", getProductModel)
                await axios.post(`${context.backendAddress}/admin/products`, (Form), {
                    headers: {
                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        toast.success("محصول با موفقیت افزوده شد", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.handleGetProducts()
                    }).catch(err => {
                        toast.error("لطفا مشخصات ساخت محصول را بصورت کامل وارد کنید", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        context.setLoading(false)
                    })
            } else {
                context.validator.current.showMessages();
                context.forceUpdate(1);
                context.setLoading(false)
            }
        } catch (ex) {
            console.log(ex);
            context.setLoading(false)
        }
    };


    return (
        <>
            <button className="btn btn-info" onClick={handleShow}>
                افزودن محصول
            </button>

            <Modal
                style={{ direction: "rtl" }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>افزودن محصول</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>نام محصول</Form.Label>
                            <Form.Control onChange={(e) => { setName(e.target.value) }} type="text" placeholder="نام محصول را وارد کنید" />
                            {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
    </Form.Text> */}
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>توضیحات</Form.Label>
                            <Form.Control onChange={(e) => { setDesc(e.target.value) }} type="text" placeholder="توضیحات محصول را وارد کنید" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>نوع پرداخت</Form.Label>
                            <Form.Control onChange={(e) => { setProductModel(e.target.value) }} as="select">
                                <option value={true}>پرداخت آزاد</option>
                                <option value={false}>پرداخت بعد از تایید</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>قیمت</Form.Label>
                            <Form.Control onChange={(e) => { setAmount(e.target.value) }} type="number" placeholder="قیمت محصول را وارد کنید" />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>عکس</Form.Label>
                            <Form.Control onChange={(e) => {
                                setPic(e.target.files[0])
                            }} type="file" placeholder="عکس محصول را وارد کنید" />
                        </Form.Group>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        بستن
                    </Button>
                    <Button onClick={handleAddProduct} variant="info">افزودن</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;
