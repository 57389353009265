import React, { Fragment, useContext, useEffect, useState } from "react";
import Table from "../../../../Common/Components/Panel/Admin/Table";
import NejatidContext from "../../../../Context/MainContext";
import Layout from "../../Layout/Page";
import { Col, Row } from 'react-bootstrap'
import Pagination from '@mui/material/Pagination'
import Modal from './Modal'
import Edite from './Edite'
import axios from "axios";
import { toast } from "react-toastify";

function Index() {
  const context = useContext(NejatidContext);
  let [getProducts, setProducts] = useState([
    {
      name: "gorgi",
      balance: "25000",
      phone: "09146315182",
      _id: "sdf",
    }
  ])

  const handleDeleteProduct = async (id) => {
    await axios.delete(`${context.backendAddress}/admin/products/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    })
      .then(res => {
        toast.success("محصول با موفقیت حذف شد", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        context.handleGetProducts()
      })
  };
  useEffect(() => {
    context.handleGetProducts();
  }, [])
  return (
    <Layout title="محصولات">
      <Fragment >
        <div className="table-admin">
          <Row className="my-3">
            <Col md={3}>
              <Modal />
              {(context.getEditeModal === true) ? (<Edite />) : (null)}
            </Col>
          </Row>
        
          {(context.getProducts === null) ? (null) : (context.getProducts.map(item =>
            <Row key={item._id} className="DivProducts mt-2">
              <Col xs={12} sm={12} md={1} lg={1} xl={1} style={{ textAlign: "center" }}>
                {(item.pic === "") ? (
                  <img src={window.origin + "/assets/AreaPanel/admin/images/user.png"} />
                ) : (
                  <img
                    style={{ width: "48px", height: "48px", borderRadius: "25px" }}
                    src={`${context.ImageAddress}/${item.pic}`}
                  />
                )}
              </Col>
              <Col className="p-2">نام محصول : <span contentEditable="true">{item.name}</span></Col>
              <Col className="p-2">توضیحات : {item.description}</Col>
              <Col className="p-2">قیمت : <span contentEditable="true">{item.price}</span> تومان</Col>
              <Col className="p-2">نوع محصول : {item.isPayble ? "قابل پرداخت" : "پرداخت پس از تایید"}</Col>
              <Col className="p-2" xs={12} sm={12} md={12} lg={1} xl={2}>
                <Row style={{ textAlign: "center" }}>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <img onClick={() => {
                      context.setEditeProduct(item)
                      context.setEditeModal(true)
                    }} style={{ width: "32px", height: "32px", cursor: "pointer" }} src={window.origin + "/assets/AreaWeb/images/edit.png"} /></Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <img onClick={() => { handleDeleteProduct(item._id) }} style={{ width: "32px", height: "32px", cursor: "pointer" }} src={window.origin + "/assets/AreaWeb/images/delete.png"} /></Col>
                </Row>
              </Col>
            </Row>
          ))}
          <br />
            <Pagination count={10} variant="outlined" onChange={context.handleGetProducts} />
        </div>
      </Fragment>
    </Layout>
  );
}

export default Index;
