import React, { Fragment , useContext } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import NejatidContext from "../../../../../Context/MainContext";
import MenuIcon from '@mui/icons-material/Menu';

function Index({ drawer, setdrawer }) {
  const context = useContext(NejatidContext)
  return (
    <nav
      className={` d-flex justify-content-between ${
        drawer ? "push-navbar " : ""
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <button
          className={`btn-bar ${drawer ? "d-none" : ""}`}
          onClick={() =>
            setdrawer(preventDefaultDrawer => !preventDefaultDrawer)
          }
        >
      <MenuIcon style={{ fontSize: "48px",color:"black" }} />
        </button>
      </div>

      <div className="logo">
        <img
          src={window.origin + "/assets/AreaPanel/images/logo.png"}
          alt="logo"
          className
        />
      </div>
      <div className="drop-down-profile">
        <DropdownButton
          dir="ltr"
          variant="warning"
          id="dropdown-basic-button"
          title={
            <Fragment>
              <span>{JSON.parse(context.getCookie("adminUsername"))}</span>
              <img
                src={window.origin + "/assets/AreaPanel/images/admin/admin.png"}
                alt="user pic"
              />
            </Fragment>
          }
        >
          <Dropdown.Item onClick={context.handleAdminLogout}>خروج</Dropdown.Item>
        </DropdownButton>
      </div>
    </nav>
  );
}

export default Index;
