import React from 'react'
import { Link } from 'react-router-dom'

function index() {
    return (
        <header className="header-main">
            <div className="logo">
                <Link to="/" ><img style={{height:"48px",padding:"10px"}} src={window.origin + '/assets/AreaWeb/images/logo.png'} alt="logo" /></Link>
            </div>
   
            <div className="left-side">
            <ul className="menu">
                <li className="item-menu"><Link style={{fontSize:"16px"}} to={`/`}>خانه</Link></li>
                <li className="item-menu"><a style={{fontSize:"16px"}} href="/#order">ثبت سفارش</a></li>
                <li className="item-menu"><a style={{fontSize:"16px"}} href="/#peygiri">پیگیری سفارش</a></li>
                <li className="item-menu"><a style={{fontSize:"16px"}} href="/#blog">وبلاگ</a></li>
                <li className="item-menu"><Link style={{fontSize:"16px"}} to={`/contact`}>تماس با ما</Link></li>
                {/* <li className="item-menu">بلاگ</li> */}
            </ul>
            </div>
        </header>
    )
}

export default index
