import React from 'react'
import Layout from '../../Layout'
import Form from '../Component/Form/index'
import Pic from '../Component/Pic/index'
import Map from '../../../Common/Components/Map'
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
function index() {
    let img = [
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
        {
            src: window.origin + '/assets/AreaWeb/images/adminss.png',
            role: "مدیر کل",
            name: "امیدزارعی"
        },
    ];
    let form = [
        {
            label: "ایمیل شما",
            type: "text"

        },
        {
            label: "موضوع",
            type: "text"

        },
        {
            label: "توضیحات",
            type: "textarea"

        },
        {
            label: "ارسال",
            type: "button"

        }
    ];
    return (
        <Layout noBoxes={true}>
            <Container>
                <section className="about-us">
                    <Row >
                        <h1 className="title">درباره قیچو</h1>
                    </Row>
                    <Row>
                        <p className="desc">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای
                            موجود طراحی اساسا مورد استفاده قرار گیرد.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جا
                            معه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                        </p>
                    </Row>
                    <Row>
                        <h2 className="us-team">تیم ما</h2>
                    </Row>
                    <Row>
                        <p className="desc-team">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای
                            موجود طراحی اساسا مورد استفاده قرار گیرد.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جا
                            معه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد   </p>
                    </Row>
                    <Row className="ceo-img">
                        <Col xs={4}>
                            <div className="box">
                                <img src={window.origin + '/assets/AreaWeb/images/adminss.png'} alt="" />
                                <div className="dec">
                                    <span className="name">علی امید </span>   <span className="role">مدیر کل</span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="box">
                                <img src={window.origin + '/assets/AreaWeb/images/adminss.png'} alt="" />
                                <div className="dec">
                                    <span className="name">علی امید </span>   <span className="role">مدیر کل</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Pic data={img} />
                    <Row>
                        <h2 className="map-title">آدرس شرکت</h2>
                    </Row>
                    <Row className="justify-content-center">
                        <div className="map-box">
                            <Map latitude={35.69816235760541} longitude={51.347036220735276} />
                        </div>
                    </Row>
                    <Row>
                        <h2 className="social-title">راه های ارتباطی</h2>
                    </Row>
                    <Row className="social">
                        <Col><div className="box"><i className="fab fa-instagram"></i><span>nejatid</span></div></Col>
                        <Col><div className="box"><i className="fab fa-twitter"></i><span>nejatid</span></div></Col>
                        <Col><div className="box"><i className="fab fa-facebook"></i><span>nejatid</span></div></Col>
                        <Col><div className="box"><i className="fab fa-telegram"></i><span>nejatid</span></div></Col>
                        <Col><div className="box"><i className="fab fa-youtube"></i><span>nejatid</span></div></Col>
                    </Row>
                    <Row className="send-com">
                        <Col md={6}>
                            <div className="frm-title">
                                <h3>انتقادات شما درباره قیچو</h3>
                            </div>
                            <Form data={form} />
                        </Col>
                        <Col md={6}>
                            <div className="img-box">
                                <img src={window.origin + '/assets/AreaWeb/images/Chatbot-pana.png'} alt="phone" />
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    )
}

export default index
