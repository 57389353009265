import { createContext } from "react";

const NejatidContext = createContext({
  getAdminUsername: String,
  getAdminPassword: String,
  getDrawer: Boolean,
  setAdminUserName: ()=>{},
  setAdminPassword: ()=>{},
  handleAdminLogin: ()=>{},
  setCookie: ()=>{},
  getCookie: ()=>{},
  setDrawer: ()=>{},
  getInfluencerLoginData:Object,
  setInfluencerLoginData:()=>{},
  handleInfluencerLogin:()=>{},
  getifo:Object,
  getifo:()=>{},
  getInfluencer:Object,
  setInfluencer:()=>{},
  onAddToBasket:()=>{},
  handleInfluencerGetFalsePayments:()=>{},
  handleInfluencerGetTruePayments:()=>{},
  getInfluencerFalsePayments:Object,
  getInfluencerFalsePayments:Object,
  
});

export default NejatidContext;
