import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";

function Index({ form, btn, title, classSize, classBtn }) {
  const [state, setstate] = useState();

  const RenderType = (input, id) => {
    switch (input.type) {
      case "profile":
        return (
          <div className="profile">
            <img
              src={window.origin + "/assets/AreaPanel/images/admin/admin.png"}
              alt="profile-image"
              style={{ width: "95px", objectFit: "contain" }}
            />
            <h2 className="text-center">amir</h2>
          </div>
        );
        break;
      case "profileLabel":
        break;
      case "text":
        return (
          <div className="input-area d-flex flex-column">
            <label htmlFor={id}>{input.name}</label>
            <input
              type="text"
              id={id}
              onChange={e =>
                setstate({ ...state, [input.key]: e.target.value })
              }
            />
          </div>
        );
      case "input-icon":
        return (
          <div className="input-area d-flex my-2">
            <img
              src={input.url}
              alt="profile-image"
              className="mx-2"
              style={{
                width: "15px",
                alignSelf: "center",
                objectFit: "contain",
              }}
            />
            <input
              type="text"
              id={id}
              onChange={e =>
                setstate({ ...state, [input.key]: e.target.value })
              }
            />
          </div>
        );
      case "file":
        break;
      case "button":
        break;
    }
  };
  return (
    <Fragment>
      <Row className="form-area justify-content-center">
        {title ? (
          <div className="title-social">
            <h5 className="text-center">{title}</h5>
          </div>
        ) : (
          ""
        )}

        <Col md={6} className="d-flex justify-content-center">
          <div className={`card-form ${classSize ? classSize : ""}`}>
            {form.map((input, id) => {
              return (
                <div className="d-flex justify-content-center" md={12}>
                  {RenderType(input, id)}
                </div>
              );
            })}
            <div className="submit-btn">
              <button className={`${classBtn ? classBtn : ""}`}>
                {btn.text}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Index;
