import React from "react";
import { Row, Col } from "react-bootstrap";

function index({ title }) {
  return (
    <Row className="title-layout">
      <Col>
        <h1>{title}</h1>
      </Col>
    </Row>
  );
}

export default index;
