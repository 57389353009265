import React, { useState, useRef } from "react";
import axios from "axios";
import NejatidContext from "./Context/MainContext";
import localforage from "localforage";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'



const GlobalState = props => {
    const [, forceUpdate] = useState();
    SimpleReactValidator.addLocale('fa', {
        accepted: ':attribute مورد قبول قرار نگرفت',
        after: ':attribute باید تاریخی بعد از :date باشد.',
        after_or_equal: ':attribute باید تاریخی برابر یا بعد از :date باشد.',
        alpha: ":attribute باید شامل حروف انگلیسی باشد.",
        alpha_space: ":attribute باید شامل حروف انگلیسی و فاصله باشد.",
        alpha_num: ":attribute باید شامل اعداد و حروف انگلیسی باشد.",
        alpha_num_space: ":attribute باید شامل اعداد و حروف انگلیسی و فاصله باشد.",
        alpha_num_dash: ":attribute باید شامل اعداد و حروف انگلیسی و خط تیره.",
        alpha_num_dash_space: ":attribute باید شامل اعداد و حروف انگلیسی، خط تیره و فاصله باشد.",
        array: ":attribute باید یک آرایه باشد.",
        before: ":attribute باید قبل از :date باشد.",
        before_or_equal: ":attribute باید قبل یا برابر با :date باشد.",
        between: ":attribute باید بین :min و :max:type باشد.",
        boolean: ":attribute باید یک بول باشد.",
        card_exp: ":attribute باید یک تاریخ انقضا معتبر باشد.",
        card_num: ":attribute باید یک شماره کارت اعتباری معتبر باشد.",
        currency: ":attribute باید یک ارز معتبر باشد.",
        date: ":attribute باید تاریخ باشد.",
        date_equals: ":attribute باید در :date بازه باشد.",
        email: ":attribute بایدآدرس ایمیل معتبر باشد.",
        in: ":attribute باید یکی از :values باشد.",
        integer: ":attribute باید یک عدد صحیح باشد.",
        max: ":attribute باید کمتر از :max:type باشد.",
        min: ":attribute باید بیشتر از :min:type باشد.",
        not_in: ":attribute نباید مشابه با :values باشد.",
        not_regex: ":attribute نباید با الگوی مورد نیاز مطابقت داشته باشد.",
        numeric: ":attribute باید عدد باشد.",
        phone: ":attribute باید یک شماره تلفن معتبر باشد.",
        regex: ":attribute باید با الگوی مورد نیاز مطابقت داشته باشد.",
        required: "وارد کردن :attribute الزامی است.",
        size: ":attribute باید تبدیل شود به size:type:.",
        string: ":attribute باید یک رشته باشد.",
        typeof: ":attribute نوع صحیح :type نوع نیست.",
        url: ":attribute باید یک آدرس اینترنتی باشد.",
    });
    const validator = useRef(
        new SimpleReactValidator({
            locale: 'fa',
            element: message => <div style={{ color: "red", fontSize: "14px" }}>{message}</div>
        })
    );
    //Admin States
    const [getSearchedContent, setSearchedContent] = useState(null);
    const [getProducts, setProducts] = useState(null);
    const [getProduct, setProduct] = useState(null);
    const [getDateStart, setDateStart] = useState(null);
    const [getDateStartShow, setDateStartShow] = useState(null);
    const [getIMEI, setIMEI] = useState(null);
    const [getMobileModel, setMobileModel] = useState(null);
    const [getCalendarDateStart, setCalendarDateStart] = useState(false);
    const [getDateEnd, setDateEnd] = useState(null);
    const [getDateEndShow, setDateEndShow] = useState(null);
    const [getCalendarDateEnd, setCalendarDateEnd] = useState(false);
    const [getIsSmsSended, setIsSmsSended] = useState(false);
    const [getPhone, setPhone] = useState(null)
    const [getFirstName, setFirstName] = useState(null)
    const [getLastName, setLastName] = useState(null)
    const [getAppleId, setAppleId] = useState(null)
    const [getEmail, setEmail] = useState(null)
    const [getDesc, setDesc] = useState(null)
    const [getPayments, setPayments] = useState(null)
    const [getPayment, setPayment] = useState(null)
    const [getPage, setPage] = useState(1)
    const [getOrderModal, setOrderModal] = useState(false)
    const [getPosts, setPosts] = useState(null)
    const [getPaymentId, setPaymentId] = useState(null)
    const [getTrackerProduct, setTrackerProduct] = useState(null)
    const [getAcceptRole, setAcceptRole] = useState(false)
    const [getDrawer, setDrawer] = useState(false)
    const [getMenu, setMenu] = useState(false)
    const [getUserRegisterStatus, setUserRegisterStatus] = useState(false)
    const [getUserPhone, setUserPhone] = useState(null);
    const [getUserPhoneCode, setUserPhoneCode] = useState(null);
    const [getCaptchaValidation, setCaptchaValidation] = useState(null)
    const [getEditeModal, setEditeModal] = useState(false)
    const [getEditeProduct, setEditeProduct] = useState(null)
    
    const backendAddress = "https://api.nejatid.com/api";
    const ImageAddress = "https://api.nejatid.com/";

    const [getAdminUsername, setAdminUsername] = useState("");
    const [getAdminPassword, setAdminPassword] = useState("");
   
    const [getLoading, setLoading] = useState(false);
    //BASKET Functions
    function changeState() {
        setDrawer(true)
    }
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const handleGetProducts = async (event,value) => {
        if(!value){
            value = 1
        }
        await axios.get(`${backendAddress}/admin/products/${value}/10`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        })
            .then(res => {
                setProducts(res.data)
            })
    };
    const handleGetOneProductForAdmin = async (content) => {
        await axios.get(`${backendAddress}/admin/products/getOne/${content}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        })
            .then(res => {
                setProduct(res.data)
            })
    };
    const handleGetOnePaymentForAdmin = async (content) => {
        await axios.get(`${backendAddress}/admin/payments/getOne/${content}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        })
            .then(res => {
                setPayment(res.data)
            })
    };
    const handleGetOneProductForUser = async (content) => {
        await axios.get(`${backendAddress}/user/products/getOne/${content}`)
            .then(res => {
                setTrackerProduct(res.data[0])
            })
    };
    const handleUserTrackOrder = async () => {
        if(getCaptchaValidation !== null){
            if (validator.current.fieldValid("شماره موبایل") && validator.current.fieldValid("کد پیگیری")) {
                setLoading(true)
                await axios.get(`${backendAddress}/user/trackOrder/${getPaymentId}/${getUserPhone}`)
                .then(res => {
                    setPayment(res.data.payment)
                    handleGetOneProductForUser(res.data.payment.productId)
                    setLoading(false)
                }).catch(err => {
                    Swal.fire(
                        {
                            title: "سفارشی با این مشخصات پیدا نشد",
                            icon: 'error',
                            confirmButtonText: 'متوجه شدم',
                        }
    
                    )
                    setLoading(false)
                })
            } else {
                validator.current.showMessages();
                forceUpdate(1);
                setLoading(false)
            }
            
        }else{
            Swal.fire(
                {
                    title: "گزینه ی من ربات نیستم را بدرستی ارسال کنید",
                    icon: 'error',
                    confirmButtonText: 'متوجه شدم',
                }

            )
        }
       
    };
    const handleGetPosts = async (event, value) => {
        if (!value) {
            value = 1
        }
        await axios.get(`https://blog.nejatid.com/wp-json/wp/v2/posts?_embed`)
            .then(res => {
                console.log(res.data)
                setPosts(res.data)
                setPage(value)
            })
    };
    const handleGetPayments = async (event, value) => {
        if (!value) {
            value = 1
        }
        await axios.get(`${backendAddress}/admin/payments/${value}/10`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        })
            .then(res => {
                setPayments(res.data)
                setPage(value)
            })
    };
    const handleGetProductsForUser = async () => {
        await axios.get(`${backendAddress}/user/products/1/100`)
            .then(res => {
                setProducts(res.data)
            })
    };
    const handleAddOrder = async () => {
        if(getAcceptRole === true){
            setLoading(true)
            try {
                if (validator.current.fieldValid("نام") && validator.current.fieldValid("نام خانوادگی") && validator.current.fieldValid("اپل آیدی") && validator.current.fieldValid("IMEI") && validator.current.fieldValid("مدل موبایل")) {
                    let body = {
                        "firstName": getFirstName,
                        "lastName": getLastName,
                        "appleId": getAppleId,
                        "imei": getIMEI,
                        "mobileModel": getMobileModel,
                        "description": getDesc,
                        "product": getProduct,
                        "email": getEmail,
                    }
                    await axios.post(`${backendAddress}/user/addPayment`, (body), {
                        headers: {
                            "x-auth-token": JSON.parse(getCookie("UserToken"))
                        }
                    })
                        .then(res => {
                            window.location.assign("/?order=true")
                        }).catch(err => {
                            console.log(err)
                        })
                } else {
                    validator.current.showMessages();
                    forceUpdate(1);
                    setLoading(false)
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false)
            }
            
        }else{
            Swal.fire(
                {
                    title: "قوانین و مقررات نجات آیدی را تایید کنید",
                    icon: 'error',
                    confirmButtonText: 'متوجه شدم',
                }

            )
        }

        
    }
    const handlePayFactor = async () => {
        try {
            await axios.get(`${backendAddress}/user/payFactor/${getPayment._id}`)
                .then(res => {
                    window.location.replace(res.data.url)
                }).catch(err => {
                    console.log(err)
                })
        } catch (ex) {
            console.log(ex);
            setLoading(false)
        }
    }
    const handleAddOnlineOrder = async () => {
        if(getAcceptRole === true){
            setLoading(true)
            try {
                if (validator.current.fieldValid("نام") && validator.current.fieldValid("نام خانوادگی") && validator.current.fieldValid("اپل آیدی") && validator.current.fieldValid("IMEI") && validator.current.fieldValid("مدل موبایل")) {
                    let body = {
                        "firstName": getFirstName,
                        "lastName": getLastName,
                        "appleId": getAppleId,
                        "imei": getIMEI,
                        "mobileModel": getMobileModel,
                        "description": getDesc,
                        "product": getProduct,
                        "email": getEmail,
                    }
                    await axios.post(`${backendAddress}/user/checkoutBasket`, (body), {
                        headers: {
                            "x-auth-token": JSON.parse(getCookie("UserToken"))
                        }
                    })
                        .then(res => {
                            window.location.replace(res.data.url)
                        }).catch(err => {
                            console.log(err)
                        })
                } else {
                    validator.current.showMessages();
                    forceUpdate(1);
                    setLoading(false)
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false)
            }
            
        }else{
            Swal.fire(
                {
                    title: "قوانین و مقررات نجات آیدی را تایید کنید",
                    icon: 'error',
                    confirmButtonText: 'متوجه شدم',
                }

            )
        }
      
    }
    const handleSearchPayment = async () => {
                await axios.get(`${backendAddress}/admin/searchPayment/${getSearchedContent}`, {
                    headers: {
                        "x-auth-token": JSON.parse(getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        toast.success("سفارش با موفقیت پیدا شد", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setPayments(res.data)
                    }).catch(err => {
                        toast.error("سفارشی با این مشخصات پیدا نشد", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        handleGetPayments()
                        setLoading(false)
                    })
    };
    const handleSearchPaymentByDate = async () => {
                await axios.get(`${backendAddress}/admin/paymentByDate/${getDateStart}/${getDateEnd}`, {
                    headers: {
                        "x-auth-token": JSON.parse(getCookie("adminToken"))
                    }
                })
                    .then(res => {
                        toast.success("سفارش با موفقیت پیدا شد", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setPayments(res.data)
                    }).catch(err => {
                        toast.error("سفارشی با این مشخصات پیدا نشد", {
                            position: "bottom-left",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        handleGetPayments()
                        setLoading(false)
                    })
    };

    //Admin Functions
    const handleAdminLogin = async () => {
        if(getCaptchaValidation !== null){
            let body = {
                "username": getAdminUsername,
                "password": getAdminPassword,
            }
            await axios.post(`${backendAddress}/admin/login`, (body))
                .then(res => {
                    setCookie('adminToken', JSON.stringify(res.headers["x-auth-token"] , 365))
                    setCookie('adminUsername', JSON.stringify(getAdminUsername) , 365)
                    window.location.assign('/admin/Orders')
                }).catch(err => {
                    console.log(err)
                })
        }else{
            Swal.fire(
                {
                    title: "گزینه ی من ربات نیستم را بدرستی ارسال کنید",
                    icon: 'error',
                    confirmButtonText: 'متوجه شدم',
                }

            )
        }
      
    }
    const handleAdminLogout = async () => {
        setCookie('adminToken', null , 365)
        window.location.assign('/admin/adminLogin')
    }
    const handleUserRegister = async (score) => {
        try {
            if (validator.current.fieldValid("موبایل")) {
                setLoading(true)
                let body = {
                    "phone": getPhone
                }
                await axios.post(`${backendAddress}/user/register`, (body)).then(res => {
                    toast.success('کد تایید برای شما ارسال شد', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsSmsSended(true)
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                })
                let div2 = document.getElementById("userCode")
                div2.value = ""
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    const handleUserLoginVerify = async (score) => {
        try {
            if (validator.current.fieldValid("کد تایید")) {
                setLoading(true)
                let body = {
                    "phone": getPhone,
                    "code": getUserPhoneCode
                }
                await axios.post(`${backendAddress}/user/verifyPhone`, (body)).then(res => {
                    setUserRegisterStatus(null)
                    setCookie('UserToken', JSON.stringify(res.headers["x-auth-token"]));
                    setLoading(false);
                    toast.success('شماره موبایل با موفقیت تایید شد', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }).catch(err => {
                    setLoading(false);
                    toast.error('کد تایید اشتباه است', {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                let div = document.getElementById("userFirstName")
                    div.value = ""
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (ex) {
            console.log(ex);
        }

    }
    axios.defaults.headers.get["Content-Type"] = "application/json";
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.delete["Content-Type"] = "application/json";
    axios.defaults.headers.put["Content-Type"] = "application/json";
 
    const store = {
        getProducts: getProducts, setProducts,getIMEI:getIMEI,setIMEI,getMobileModel:getMobileModel,setMobileModel,
        handleGetProducts,getUserRegisterStatus:getUserRegisterStatus,setUserRegisterStatus,
        handleGetProductsForUser,
        handlePayFactor,getCalendarDateStart:getCalendarDateStart,setCalendarDateStart,
        backendAddress: backendAddress,changeState,
        ImageAddress: ImageAddress,
        getProduct: getProduct, setProduct,
        getIsSmsSended: getIsSmsSended, setIsSmsSended,
        getPhone, getPhone, setPhone,
        getFirstName: getFirstName, setFirstName, getLastName: getLastName, setLastName, getAppleId: getAppleId, setAppleId,
        getEmail: getEmail, setEmail, getDesc: getDesc, setDesc, handleAddOrder, handleAddOnlineOrder,
        handleGetPayments, getPayments: getPayments, setPayments, getPage: getPage, setPage, getPayment: getPayment, setPayment,
        getOrderModal: getOrderModal, setOrderModal, handleGetOneProductForAdmin, handleGetOnePaymentForAdmin,
        getPaymentId: getPaymentId, setPaymentId, handleUserTrackOrder, handleGetOneProductForUser, getTrackerProduct: getTrackerProduct, setTrackerProduct,
        handleAdminLogout,getAcceptRole:getAcceptRole,setAcceptRole,
        getDrawer:getDrawer,setDrawer,getCaptchaValidation:getCaptchaValidation,setCaptchaValidation,
        getMenu:getMenu,setMenu,getEditeModal:getEditeModal,setEditeModal,
        getUserPhone: getUserPhone,handleGetPosts,getPosts:getPosts,setPosts,
        setUserPhone,getEditeProduct:getEditeProduct,setEditeProduct,
        getUserPhoneCode: getUserPhoneCode,getSearchedContent:getSearchedContent,setSearchedContent,
        setUserPhoneCode,handleSearchPaymentByDate,
        getAdminUsername: getAdminUsername,
        setAdminUsername,
        getAdminPassword: getAdminPassword,
        setAdminPassword,
        handleAdminLogin,
        getCookie,handleSearchPayment,
        setCookie,
        getLoading: getLoading,getCalendarDateEnd:getCalendarDateEnd,setCalendarDateEnd,
        setLoading,getDateEnd:getDateEnd,setDateEnd,getDateEndShow:getDateEndShow,setDateEndShow,
        handleUserRegister,getDateStart:getDateStart,setDateStart,
        handleUserLoginVerify,getDateStartShow:getDateStartShow,setDateStartShow,
        validator,
        forceUpdate,
    };

    return (
        <NejatidContext.Provider value={store}>
            {props.children}
        </NejatidContext.Provider>
    );
};

export default GlobalState;

