import React, { useContext } from 'react'
import Layout from '../../Layout'
import Form2 from '../Component/Form/index'
import { Col, Container, Nav, Row, Tab, Form } from "react-bootstrap";
import NejatidContext from '../../../Context/MainContext'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Index() {
    const context = useContext(NejatidContext)
    useEffect(() => {
        console.log(context.getCookie("product"))
        if(context.getCookie("product") === ""){
            window.location.assign("/")
        }else{
            context.setProduct(JSON.parse(context.getCookie("product")))
        }
        window.scroll(0, 0)
    }, [])
    return (
        <Layout noBoxes={true}>
            <Container>
                <section className="about-us">
                    <Row >
                        <h1 className="title">نهایی سازی سفارش</h1>
                    </Row>

                    <Row className="send-com">

                        <Col md={6}>
                            <div className="img-box">
                                {(context.getProduct !== null) ? (<Col xs={12} sm={12} lg={12}>
                                    <div className="card-box text-center">
                                        <div className="image-box">

                                            {(context.getProduct.pic === "") ? (
                                                <img src={window.origin + "/assets/AreaWeb/images/girl.png"} />
                                            ) : (
                                                <img
                                                    src={`${context.ImageAddress}/${context.getProduct.pic}`}
                                                />
                                            )}
                                            <div className="price">
                                                <span>{context.getProduct.name}</span>
                                            </div>
                                        </div>
                                        <div className="data mt-5">
                                            <span className="desc">{context.getProduct.description}</span>
                                        </div>
                                        <Row className="d-flex flex-row justify-content-center align-items-center">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                {(context.getProduct.isPayble) ? (
                                                    <span style={{ marginBottom: "0px", fontSize: "12px",color:"#4C3D3D"}}>قیمت : {`${`${context.getProduct.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} تومان`}</span>
                                                ) : (
                                                    <>
                                                        <span style={{ marginBottom: "0px", fontSize: "12px", color: "rgb(186 34 34)" }}>قیمت : <span className="text-info p-2">نیاز به بررسی</span></span>
                                                        <br />
                                                        <p style={{ marginBottom: "0px", fontSize: "12px", color: "black", padding: "10px" }}>آیتم هایی که نیاز به بررسی دارند از طرف شما نیازی به پرداخت ندارند و شما بصورت رایگان میتوانید سفارش خود را ثبت کنید پس از بررسی توسط ادمین و قابل انجام بودن سفارش مبلغ و توضیحات برای سفارش شما درج میشود و شما در قسمت پیگیری سفارش میتوانید پرداخت خود را انجام دهید و از آنلاک اپل آیدی خود لذت ببرید</p></>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>) : (null)}
                            </div>
                        </Col>
                        <Col md={6}>
                            {(!context.getCookie("UserToken")) ? (<>
                                <div className="frm-title">
                                    <h3>تایید شماره موبایل</h3>
                                </div>
                                <div className="form-ques-box">
                                    {(context.getIsSmsSended) ? (<div className="inp-box">
                                        <label for="code">کد تایید</label>
                                        <input autoFocus={true} onChange={(e) => {
                                            context.setUserPhoneCode(e.target.value)
                                            context.validator.current.showMessageFor("کد تایید");
                                        }} type="number" id="userCode" name="کد تایید" />
                                        {context.validator.current.message(
                                            "کد تایید",
                                            context.getUserPhoneCode,
                                            "required|min:4|max:4"
                                        )}
                                    </div>) : (<div className="inp-box">
                                        <label for="mobile">شماره موبایل</label>
                                        <input autoFocus={true} onChange={(e) => {
                                            context.setPhone(e.target.value)
                                            context.validator.current.showMessageFor("موبایل");
                                        }} type="number" id="userMobile" name="موبایل" />
                                        {context.validator.current.message(
                                            "موبایل",
                                            context.getPhone,
                                            "required|min:11|max:11"
                                        )}
                                    </div>)}

                                    {(context.getIsSmsSended) ? (<div className="bt-box">
                                        <button onClick={() => { context.handleUserLoginVerify() }} disabled={(context.getLoading === true) ? (true) : (false)}>{(context.getLoading === true) ? ("در حال بررسی") : ("تایید کد")}</button>
                                    </div>) : (<div className="bt-box">
                                        <button onClick={() => { context.handleUserRegister() }} disabled={(context.getLoading === true) ? (true) : (false)}>{(context.getLoading === true) ? ("در حال ارسال") : ("ارسال پیامک")}</button>
                                    </div>)}

                                </div>
                            </>) : (<>
                                <div className="frm-title">
                                    <h3>اطلاعات شما</h3>
                                </div>
                                <div className="form-ques-box">
                                    <div className="inp-box">
                                        <label for="fname">نام</label>
                                        <input autoFocus={true} onChange={(e) => {
                                            context.setFirstName(e.target.value)
                                            context.validator.current.showMessageFor("نام");
                                        }} type="text" id="userFirstName" name="userFirstName" />
                                        {context.validator.current.message(
                                            "نام",
                                            context.getFirstName,
                                            "required|min:3"
                                        )}
                                    </div>
                                    <div className="inp-box">
                                        <label for="fname">نام خانوادگی</label>
                                        <input onChange={(e) => {
                                            context.setLastName(e.target.value)
                                            context.validator.current.showMessageFor("نام خانوادگی");
                                        }} type="text" id="fname" name="fname" />
                                        {context.validator.current.message(
                                            "نام خانوادگی",
                                            context.getLastName,
                                            "required|min:3"
                                        )}
                                    </div>
                                    <div className="inp-box">
                                        <label for="fname">اپل آیدی</label>
                                        <input onChange={(e) => {
                                            context.setAppleId(e.target.value)
                                            context.validator.current.showMessageFor("اپل آیدی");
                                        }} type="text" id="fname" name="fname" />
                                        {context.validator.current.message(
                                            "اپل آیدی",
                                            context.getAppleId,
                                            "required|min:8"
                                        )}
                                    </div>
                                    <div className="inp-box">
                                        <label for="fname">IMEI</label>
                                        <input onChange={(e) => {
                                            context.setIMEI(e.target.value)
                                            context.validator.current.showMessageFor("IMEI");
                                        }} type="text" id="IMEI" name="IMEI" />
                                        {context.validator.current.message(
                                            "IMEI",
                                            context.getIMEI,
                                            "required"
                                        )}
                                    </div>
                                    <div className="inp-box">
                                        <label for="fname">مدل گوشی</label>
                                        <input onChange={(e) => {
                                            context.setMobileModel(e.target.value)
                                            context.validator.current.showMessageFor("مدل موبایل");
                                        }} type="text" id="mobileModel" name="مدل موبایل" />
                                        {context.validator.current.message(
                                            "مدل موبایل",
                                            context.getMobileModel,
                                            "required"
                                        )}
                                    </div>
                                    <div className="inp-box">
                                        <label for="fname">ایمیل</label>
                                        <input onChange={(e) => {
                                            context.setEmail(e.target.value)
                                        }} type="text" id="fname" name="fname" />
                                    </div>
                                    <div className="inp-box">
                                        <label for="fname">توضیحات</label>
                                        <textarea onChange={(e) => {
                                            context.setDesc(e.target.value)
                                        }} type="text" id="fname" name="fname" />
                                    </div>
                                </div>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" onChange={(e) => { context.setAcceptRole(e.target.checked) }} />
                                <label for="vehicle1">من با <span style={{color:"red"}}><Link style={{color:"red"}} to="/terms">قوانین و مقررات</Link></span> نجات آیدی موافقم</label>
                                {(context.getProduct !== null) ? (<div className="form-ques-box">
                                    {(context.getProduct.isPayble) ? (
                                        <div className="bt-box">
                                            <button onClick={() => { context.handleAddOnlineOrder() }}>پرداخت سفارش</button>
                                        </div>
                                    ) : (
                                        <div className="bt-box">
                                            <button onClick={() => { context.handleAddOrder() }}>ثبت سفارش</button>
                                        </div>
                                    )}

                                </div>) : (null)}
                                
                            </>)}



                        </Col>
                    </Row>

                </section>
            </Container>
        </Layout>
    )
}

export default Index
