import React, { useState } from "react";
import Navbar from "../../../../Common/Components/Panel/Admin/Navbar";
import Drawer from "../../../../Common/Components/Panel/Admin/Drawer";
import { Container } from "react-bootstrap";
import Title from "../../../../Common/Components/Panel/Admin/Title";

function Index({ children, title }) {
  const [drawer, setdrawer] = useState(false);
  return (
    <div className="d-flex" style={{ flex: 1, backgroundColor: "#F8F9FA" }}>
      <Drawer drawer={drawer} setdrawer={setdrawer} />
      <div style={{ flex: 1 }} className="d-flex flex-column">
        <Navbar drawer={drawer} setdrawer={setdrawer} />
        <Container className={`${drawer ? "push-navbar" : ""}`}>
          <div className="content-arewa">
            <Title title={title} />
            {children}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Index;
