import React, { Fragment, useContext } from 'react'
import Header from '../../Common/Components/HeaderWeb'
import LeftBoxs from '../../Common/Components/LeftBoxs'
import FooterWeb from '../../Common/Components/FooterWeb'
import { Col, Container, Row } from 'react-bootstrap'
import { Hidden, IconButton, Link } from '@material-ui/core'
import MenuIcon from '@mui/icons-material/Menu';
import NejatidContext from '../../Context/MainContext'
import Drawer from './Drawer'

function Index({ children, noBoxes }) {
    const context = useContext(NejatidContext)
    return (
        <section className="background-content" dir="rtl">
            <Hidden only={["xs", "sm"]}>
                <Header />
                {
                    noBoxes ? "" : <LeftBoxs />
                }
                   <br/>
                   <br/>
            </Hidden>
            <Hidden only={["lg", "xl", "md"]}>
                <Row className="d-flex flex-row justify-content-st align-items-center mb-4" style={{ height: "60px", margin: "0" }}>
                    <Col xs={4} sm={3}>
                        <IconButton onClick={()=>{
                            context.setMenu(true)
                            }} size="large">
                            <MenuIcon style={{ fontSize: "48px",color:"white" }} />
                        </IconButton>
                    </Col>
                    <Col xs={8} sm={9} style={{ textAlign: "left" }}>
                        <Link to="/" ><img style={{ width: "64px" }} src={window.origin + '/assets/AreaWeb/images/logo.png'} /></Link>
                    </Col>
                </Row>
            </Hidden>

            {children}
            <Container>
                <FooterWeb />
            </Container>
            <Drawer />
        </section>
    )
}

export default Index
