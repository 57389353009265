import React, { Fragment, useContext, useEffect, useState } from "react";
import Table from "../../../../Common/Components/Panel/Admin/Table";
import NejatidContext from "../../../../Context/MainContext";
import Layout from "../../Layout/Page";
import { Col, Row } from 'react-bootstrap'
import Pagination from '@mui/material/Pagination'
import Modal from './Modal'
import DateStart from './DateStart'
import DateEnd from './DateEnd'
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

function Index() {
  const context = useContext(NejatidContext);
  const [openSearch , setOpenSearch] = useState(false)
  function printDiv(divName) {
     var printContents = document.getElementById(divName).innerHTML;
     var originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
}
  useEffect(() => { 
    if (!context.getCookie("adminToken") || context.getCookie("adminToken") === "null") {
      window.location.assign("/admin/adminLogin")
    }
    context.handleGetPayments();
  }, [])
  return (
    <Layout title="سفارشات">
      <Fragment >
        <div className="table-admin">
          <Modal />
          <Row className="d-flex flex-row justify-content-between align-items-start">

            <Col xs={4} sm={4} md={4}>
              <div className="search-box">
                <input onChange={(e) => { context.setSearchedContent(e.target.value) }} type="number" placeholder="(شماره سفارش)جستجو کنید ..." />
                <SearchIcon onClick={() => { context.handleSearchPayment() }} style={{ cursor: "pointer" }} />
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} style={{ textAlign: "left" }}>
            <Row className="d-flex flex-row justify-content-end align-items-center">
            <Col xs={2} sm={2} md={2} xl={2} lg={2} style={{ textAlign: "left" }}>
            <PrintIcon onClick={()=>{printDiv("orders")}} style={{ cursor: "pointer" }} />
            </Col>
            <Col xs={2} sm={2} md={2} xl={2} lg={2} style={{ textAlign: "left" }}>
               <SortIcon fontSize="large" onClick={() => { setOpenSearch(!openSearch) }} style={{ cursor: "pointer" }} />
            </Col>
            </Row>
               
            </Col>
          </Row>
          {(openSearch === true) ? ( <Row className="d-flex flex-row justify-content-between align-items-center mt-3 mb-3">
            <Col xs={12} sm={12} md={4}>
              <DateStart />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <DateEnd />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <button className="btnSearchi" onClick={()=>{context.handleSearchPaymentByDate()}}>جستجو</button>
            </Col>
          </Row>) : (false)}
         <div id="orders">
         {(context.getPayments === null) ? (null) : (context.getPayments.map(item =>
            <Row style={{ margin: "0" }} onClick={() => {
              context.setOrderModal(true)
              context.setPayment(item)
              context.handleGetOneProductForAdmin(item.productId)
            }} className="DivProducts mt-2">
              {/* <Col className="p-2" xs={10} sm={10} md={10} lg={11} xl={11}><img style={{ width: "64px", height: "64px",textAlign:"right"}} src={window.origin + "/assets/AreaPanel/images/admin/admin.png"} /></Col> */}
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>کد سفارش : <span>{item.PaymentNumber}</span></Col>
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>تاریخ سفارش : {new Date(item.createdDate).toLocaleDateString("fa")}</Col>
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>نام : <span contentEditable="true" >{item.firstName}</span></Col>
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>نام خانوادگی : <span contentEditable="true" >{item.lastName}</span></Col>
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>شماره موبایل : <span contentEditable="true" >0{item.userPhone}</span></Col>
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>ایمیل : <span contentEditable="true" >{item.email}</span></Col>
              <Col className="p-2" xs={6} sm={6} md={6} lg={6} xl={6}>اپل آیدی : <span contentEditable="true" >{item.appleId}</span></Col>
              <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6}>قیمت : <span contentEditable="true" >{item.amount}</span> تومان</Col>
              <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6}>نوع محصول : {item.isPayble ? "قابل پرداخت" : "پرداخت پس از تایید"}</Col>
              <Col className="p-2" xs={12} sm={12} md={6} lg={6} xl={6}>وضعیت پرداخت : {item.success ? (<span className="text-success">پرداخت موفق</span>) : (<span className="text-danger">پرداخت نشده</span>)}</Col>
            </Row>
          ))}
         </div>
          
          <br />
          <Pagination count={10} variant="outlined" onChange={context.handleGetPayments} />
        </div>
      </Fragment>
    </Layout>
  );
}

export default Index;
