import React,{useContext} from 'react'
import { Col,Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import NejatidContext from '../../../../Context/MainContext';
function Index({data}) {
const context = useContext(NejatidContext)
    return (
        <Col xs={12} sm={4} lg={3}>
            <Link onClick={()=>{
                context.setCookie("product",JSON.stringify(data),1)
                }} className="text-light" to={`/order`}>
            <div className="card-box text-center">
                <div className="image-box">
                    {(data.pic === "") ? (
                        <img src={window.origin + "/assets/AreaWeb/images/girl.png"} />
                    ) : (
                        <img
                            src={`${context.ImageAddress}/${data.pic}`}
                        />
                    )}

<div className="price">
                        <p >{data.name}</p>
                    </div>
                </div>
 
                    <div className="data mt-5">
                    <p style={{height:"60px"}} className="desc p-3">{data.description}</p>
                </div>
                <Row className="d-flex flex-row justify-content-center align-items-center">
                    <Col xs={8} sm={9} md={9} lg={8} xl={8}>
                        {(data.isPayble) ? (
                            <span style={{marginBottom:"0px",fontSize:"12px",color:"#4C3D3D"}}>قیمت : {`${`${data.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} تومان`}</span>
                        ) : (
<span style={{marginBottom:"0px",fontSize:"12px",color:"#4C3D3D"}}><span>پرداخت پس از تایید</span></span>
                        )}
                        
                    </Col>
                    <Col xs={4} sm={3} md={3} lg={4} xl={4}>
                   <div className="data">
                   <img style={{width:"32px",height:"32px"}} src={window.origin + "/assets/AreaWeb/images/buyButton.png"} />
                   </div>
                    </Col>
                </Row>
            </div>
                   </Link>
        </Col>
    )
}

export default Index
