import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { faIR } from '@mui/material/locale';
import 'animate.css';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// Admin Start
import adminOrders from "./Panel/Admin/Orders/Page/index";
import AdminLogin from "./Panel/Admin/Auth/Login/Page/Index";
import EditUser from "./Panel/Admin/Users/Page/Edit.jsx";
import Products from "./Panel/Admin/Products/Page";


// Panel Start
import AboutUs from './Web/Aboutus/Pages/index';
import ContactUs from './Web/Contact/Pages/index';
import AddOrder from './Web/AddOrder/Pages/index';
import Home from './Web/Landing/Pages/index';
import Terms from './Web/Terms/Pages/index'
import GlobalState from "./GlobalState";

const theme = createTheme(
  faIR,
  {direction: 'rtl'}
);
ReactDOM.render(
  <GlobalState>
    <ThemeProvider theme={theme} localforage>
    <BrowserRouter>
        <Switch>
          <Route path={"/terms"} exact component={Terms} />
          <Route path={"/contact"} exact component={ContactUs} />
          <Route path={"/order"} exact component={AddOrder} />
          <Route path={"/aboutus"} exact component={AboutUs} />


          <Route path={"/"} exact component={Home} />
          <Route path={"/admin/AdminLogin"} exact component={AdminLogin} />
          <Route path={"/admin/Orders"} exact component={adminOrders} />
          <Route path={"/admin/Products"} exact component={Products} />
          {/* Panel influencer End */}
          {/* panel admin start */}
          <Route path={"/admin/useredit"} exact component={EditUser} />
          {/* panel admin end */}
        </Switch>
      </BrowserRouter>
</ThemeProvider>

      <ToastContainer />
  </GlobalState>,
  document.getElementById("root")
);
