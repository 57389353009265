import React, { useState , useContext } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl';
import Mark from './Components/Mark';
import NejatidContext from './../../../Context/MainContext';


function Map({ latitude, longitude }) {
    const [viewport, setViewport] = useState({
        latitude: latitude,
        longitude: longitude,
        zoom: 16
    });
    const context = useContext(NejatidContext);
    return (
        <ReactMapGL
            {...viewport}
            width="100%"
            height="300px"
            mapStyle="mapbox://styles/omidzarei/ckuve9p001zz218uvhp6qmvrk"
            mapboxApiAccessToken="pk.eyJ1Ijoib21pZHphcmVpIiwiYSI6ImNrdXZkdGdnZTA4cW8ycG12eHNld21lY3MifQ.jYe4U-1rKe-TcyNd74EajQ"
            onViewportChange={(viewport) => {setViewport(viewport)}}
        >
            <Mark mark={viewport} />
        </ReactMapGL>
    )
}

export default Map
